import { createI18n } from "vue-i18n/dist/vue-i18n.runtime.esm-bundler"; //Production or CSP Debug
// import { createI18n } from "vue-i18n";  //Debug

const i18n = createI18n({
  locale: "en",
  messages: {
    ch: require("@/language/ch.json"),
    en: require("@/language/en.json"),
  },
});

export default i18n;
