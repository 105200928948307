import axios from "axios";
import store from "@/store/index.js";

let domain = process.env.VUE_APP_API_DOMAIN;
let namespace = process.env.VUE_APP_API_NAMESPACE;
let baseUrl = `${domain}/${namespace}`;
let baseConfig = {
  baseURL: baseUrl,
  timeout: 60000,
};
let apiKey = process.env.VUE_APP_API_KEY;
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (!config.url) {
      return config;
    }
    Object.entries(config.urlParams || {}).forEach(([k, v]) => {
      config.url = config.url.replace(
        `:${k}`,
        encodeURIComponent(v)
      );
    });

    return { ...config, url: config.url };
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (Object.prototype.hasOwnProperty.call(response.headers, "x-token")) {
      let jwt = response.headers["x-token"];
      let target = response.config.url.split("/");
      target = target.pop();
      switch (target) {
        case "login":
          store.commit('updateRCDCuCJwt', jwt);
          break;
        case "maintenance":
          break;
        default: {
          store.commit(`updateRCDJWT`, jwt);
          break;
        }
      }
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default {
  get(url, config = {}) {
    return this.call(url, "get", null, config);
  },
  post(url, data, config = {}) {
    return this.call(url, "post", data, config);
  },
  put(url, data, config = {}) {
    return this.call(url, "put", data, config);
  },
  delete(url, data, config = {}) {
    return this.call(url, "delete", null, config);
  },
  call(url, method, data, config = {}) {
    let param = {
      url: url,
      method: method,
      data: data,
      headers: {
        "Content-Type": "application/json;charset=UTF8",
        "Api-Key": apiKey,
      },
    };

    param = Object.assign(param, baseConfig);
    Object.entries(config).forEach(([key, value]) => {
      if (
        Object.prototype.hasOwnProperty.call(param, key) &&
        typeof param[key] === "object"
      ) {
        param[key] = { ...param[key], ...value };
      } else {
        param[key] = value;
      }
    });
    return axios(param);
  },
};
