const constants = {
  locale: {
    en: "en",
    ch: "ch",
  },
  applicantType: {
    resident: "R",
    noProofResidents: "V",
    under18Residents: "C",
    worker: "W",
    student: "S"
  },
  proofType: {
    residen: "R",
    village: "V",
    worker: "W",
    student: "S"
  },
  unit: {
    character: ["character", "characters"],
    digit: ["digit", "digits"],
    year: ["year old", "years old"],
    month: ["month", "months"]
  },
  stepType: {
    new: "N",
    application: "A",
    AppInfo: "I"
  },
  dataType: {
    file: "File",
    base64: "base64"
  },
  checkingStepType: {
    identity: "identity",
    octopus: "octopus",
    closedAreaPermit: "closedAreaPermit",
    proof: "proof",
    relationship: "relationship"
  },
  uploadType: {
    image: "image",
    pdf: "pdf"
  },
  folderType: {
    Permit: 0,
    Proof: 1
  }
}

export default constants;
