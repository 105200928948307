import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import rcd from "./personal";
import rcdCuC from "./cuc";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    rcd,
    rcdCuC
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: "rcd",
      paths: ["rcd"],
    }),
    createPersistedState({
      storage: window.sessionStorage,
      key: "rcdCuC",
      paths: ["rcdCuC"],
    })
  ],
  strict: false,
});
