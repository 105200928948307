import http from "@/http";

let modulePath = "Health";
const get = function (path, config = {}) {
  return http.get(`${modulePath}/${path}`, config);
};

export default {
  getHealth(jwt) {
    return get("", {
      headers: {
        Authorization: "Bearer " + jwt
      }
    });
  },
}