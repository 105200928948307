import http from "@/http";

let modulePath = "Master";
const get = function (locale, path, config = {}) {
  return http.get(`${locale}/${modulePath}/${path}`, config);
};

export default {
  getDistricts(locale) {
    return get(locale, "districts");
  },
  getRegions(locale) {
    return get(locale, "regions");
  },

  getRegionByRegionCode(locale, code) {
    return get(locale, "region", {
      params: {
        regionCode: code,
      },
    });
  },

  getDistrictByDistrictCode(locale, code) {
    return get(locale, "district", {
      params: {
        districtCode: code,
      },
    });
  },

  getMaintenance(locale) {
    return get(locale, "maintenance", {
      headers: {
        "X-Access-Id": "Mtr"
      }
    });
  },
  getAppValidationStatus(locale = "en") {
    return get(locale, "inAppValidation");
  }
};
