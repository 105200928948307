import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store"
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import VueI18n from "./language/index.js"
import * as ElIconModules from "@element-plus/icons-vue"
import "bootstrap-icons/font/bootstrap-icons.css"

const app = createApp(App);

for (let iconName in ElIconModules) {
	app.component(iconName, ElIconModules[iconName])
}

app.use(store).use(router).use(ElementPlus, { size: 'large'}).use(VueI18n)

app.mount('#app');

export { app };