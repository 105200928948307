const defaultSearch = () => {
  return {
    appNo: null,
    partialId: null
  }
};

const defaultForm = () => {
  return {
    formId: null,
    applyDate: null,
    personalInformation: {
      identityType: null,
      identityNumber: null,
      name: null,
      nameChi: null,
      gender: null,
      birthday: null
    },
    address: {
      address1: null,
      address2: null,
      address3: null,
      district: null,
      region: null
    },
    octopusInformation: {
      cardNumber: null,
      checksum: null
    },
    parent: {
      relationship: null,
      name: null,
      nameChi: null,
      identityType: null,
      identityNumber: null
    },
    permitNo: null,
    proofType: null
  };
};

const defaultFileObj = () => {
  return {
    type: null,
    data: null
  }
}

export default {
  state: {
    jwt: null,
    search: defaultSearch(),
    form: defaultForm(),
    checkingStep: null,
    closedAreaPermit: defaultFileObj(),
    proof: defaultFileObj()
  },
  getters: {
    rcdCuCJwt: (state) => {
      return state.jwt;
    }
  },
  mutations: {
    updateRCDCuCJwt(state, val) {
      state.jwt = val;
    },
    updateRCDCuCSearch(state, val) {
      state.search = val;
    },
    updateRCDCuCCheckingStep(state, val) {
      state.checkingStep = val;
    },
    bindRCDCuCForm(state, val) {
      state.form.formId = val.formId,
      state.form.applyDate = val.applyDate;
      state.form.personalInformation.identityType = val.personalInformation.identityType;
      state.form.personalInformation.identityNumber = val.personalInformation.identityNumber;
      state.form.personalInformation.name = val.personalInformation.name;
      state.form.personalInformation.nameChi = val.personalInformation.nameChi;
      state.form.personalInformation.gender = val.personalInformation.gender;
      state.form.personalInformation.birthday = val.personalInformation.birthday;
      state.form.address.address1 = val.address.address1;
      state.form.address.address2 = val.address.address2;
      state.form.address.address3 = val.address.address3;
      state.form.address.district = val.address.district;
      state.form.address.region = val.address.region;
      state.form.octopusInformation.cardNumber = val.octopusInformation.cardNumber;
      state.form.octopusInformation.checksum = val.octopusInformation.checksum;
      if(val.parent) {
        state.form.parent.relationship = val.parent.relationship;
        state.form.parent.name = val.parent.name;
        state.form.parent.nameChi = val.parent.nameChi;
        state.form.parent.identityType = val.parent.identityType;
        state.form.parent.identityNumber = val.parent.identityNumber;        
      }
      state.form.permitNo = val.permitNo;
      state.form.proofType = val.proofType;
    },
    bindClosedAreaPermit(state, val) {
      state.closedAreaPermit = val;
    },
    bindProof(state, val) {
      state.proof = val;
    },
    resetRCDCuC(state) {
      state.jwt = null;
      state.search = defaultSearch(),
      state.form = defaultForm(),
      state.checkingStep = null,
      state.closedAreaPermit = defaultFileObj(),
      state.proof = defaultFileObj()
    },
    resetRCDCuCForm(state){
      state.search = defaultSearch(),
      state.form = defaultForm();
      state.checkingStep = null;
      state.closedAreaPermit = defaultFileObj();
      state.proof = defaultFileObj();
    },
  }
}