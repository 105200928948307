<template>
  <el-container>
    <el-header class="header">
      <el-row>
        <el-col :lg="3" :sm="5" :xs="5" class="logo">
          <img style="float: left; height: 40px" src="./assets/logo.png" />
        </el-col>
        <el-col
          :lg="19"
          :sm="16"
          :xs="16"
          class="title"
          style="padding-left: 10px; padding-right: 10px"
        >
          <h2 class="title">{{ $t("title") }}</h2>
        </el-col>
        <el-col :lg="2" :sm="3" :xs="3" class="lang">
          <el-button round @click="switchLang">{{ $t("lang") }}</el-button>
        </el-col>
      </el-row>
    </el-header>
    <p class="xs-title">
      <span>{{ $t("title") }}</span>
    </p>
    <el-main v-loading="loading">
      <router-view />
    </el-main>
    <el-footer>
      {{ $t("copyright") }}
    </el-footer>
  </el-container>
</template>

<script>
import file from "./http/apis/file";
import master from "./http/apis/master";
import store from "@/store";
import * as message from "@/plugins/message.js";
import "@/style/common.css";

export default {
  name: "App",
  data() {
    return {
      loading: true
    };
  },
  methods: {
    switchLang() {
      let router = this.$router;
      router.replace({
        name: router.name,
        params: {
          locale: this.$i18n.locale == "en" ? "ch" : "en",
        },
      });
    },
  },
  async beforeMount() {

    //Overwrite i18n resource files
    try {
      let config = await file.geti18nFile("config.json", window.location.origin);
    
      if (config.status == 200) {
        if (config.data.override) {
          let locales = ["en", "ch"];
          for (let i = 0; i < locales.length; i++) {
            let langObj = await file.geti18nFile(`${locales[i]}.json`, window.location.origin);
            if (langObj.status == 200) {
              if (langObj.data) {
                this.$i18n.setLocaleMessage(locales[i], langObj.data);
              }
            }
          }
        }
      }
    } catch(err) {
      //console.log(err)
    }
    
    let rcd = store.state.rcd
    try {   
      if (rcd.form.name === null) {
        store.commit("resetNameObj")
        if (rcd.form.name === null) {
          message.WarningAlertWithCallback(this.$i18n.t("exceptions.cleanCache"), "", {
            closable: false,
            callback: ()=> {window.location.reload()}
          })
          return;
        }
      }
    } catch(err) {
      alert("Error")
    }

    master.getMaintenance("en").then((res) => {
      if (res.data) {
        this.$router.replace({name: "Maintenance", params: {locale: this.$i18n.locale}})
      }
    }).catch(() => {
      this.$router.push({name: "Error", params: {locale: this.$i18n.locale}})
    }).finally(() => {
      this.loading = false;
    });
    //check if mobile device
    store.commit("updateIsMobile", window.matchMedia("(pointer:coarse)").matches)
  },
  watch: {
    '$i18n.locale': {
      handler() {
        document.title = this.$i18n.t("title");
      }
    }
  },
};
</script>
