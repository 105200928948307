import { createRouter, createWebHistory } from "vue-router"
import constants from "@/libs/constants.js"
import $i18n from "@/language/index.js"

let cucPath = "/:locale/cuc"
let personalPath = "/:locale/personal"

const routes = [
	{
		path: "/",
		redirect: {
			name: "Personal",
			params: {
				locale: constants.locale.en
			}
		}
	},
	{
		name: "Home",
		path: "/:locale",
		redirect: to => {
			let locale = to.params.locale
			let locales = constants.locale
			let i18n = $i18n.global
			let path = to.path
			if (Object.prototype.hasOwnProperty.call(locales, locale)) {
				i18n.locale = locale
			} else {
				i18n.locale = locales.en
				path = locale
			}
			return {
				name: "Process",
				params: {
					locale: i18n.locale,
					part: path
				},
				query: to.query
			}
		}
	},
	{
		name: "Process",
		path: "/:locale/:part",
		redirect: to => {
			let part = to.params.part
			let obj = {
				params: {
					locale: to.params.locale
				},
				query: to.query
			}
			switch (part) {
				case "cuc":
					obj.name = "CUC"
					break
				default:
					obj.name = "Personal"
					break
			}
			return obj
		}
	},
	{
		name: "CUC",
		path: `${cucPath}`,
		component: () => import("@/views/cuc/CUC.vue"),
		redirect: { name: "CUCLoginPassCode" },
		children: [
			{
				name: "CUCLoginPassCode",
				path: "login",
				component: () => import("@/views/cuc/Login.vue")
			}
		]
	},
	{
		name: "CUCRCDInspectionPlatform",
		path: `${cucPath}/inspection`,
		component: () => import("@/views/personal/Personal.vue"),
		redirect: { name: "Inspection" },
		children: [
			{
				name: "Inspection",
				path:"",
				component: () => import("@/views/cuc/Inspection")
			}
		]
	},
    {
        name: "Personal",
        path: personalPath,
        component: () => import("@/views/personal/Personal.vue"),
        redirect: () => {
			return { name: "StepStart" };
        },
        children: [
          {
            name: "StepStart",
            path: "",
            component: () => import("@/views/personal/steps/Start.vue"),
          },
          {
            name: "StepAgreement",
            path: "agreement",
            component: () => import("@/views/personal/steps/Agreement.vue"),
          },
          {
            name: "StepType",
            path: "type",
            component: () => import("@/views/personal/steps/Type.vue"),
          },
          {
            name: "StepForm",
            path: "form",
            component: () => import("@/views/personal/steps/Form.vue"),
          },
          {
            name: "StepConfirm",
            path: "confirm",
            component: () => import("@/views/personal/steps/Confirmation.vue"),
          },
          {
            name: "StepComplete",
            path: "complete",
            component: () => import("@/views/personal/steps/Complete.vue"),
          },
          {
            name: "RCDAppInfo",
            path: "info",
            component: () => import("@/views/personal/ApplicationInfo.vue"),
          },
          {
            name: "Maintenance",
            path: "maintenance",
            component: () => import("@/views/personal/Maintenance.vue"),
          },
          {
            name: "Error",
            path: "error",
            component: () => import("@/views/Error.vue"),
          },
          {
            path: ":catchAll(.*)",
            beforeEnter: (to) => {
              if(!to.params.catchAll) {
                return {name: "StepStart", params:{locale: to.params.locale}}
              }
            },
            component: () => import("@/views/404.vue")
          }
        ],
    }
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	let locale = to.params.locale
	let locales = constants.locale
	let i18n = $i18n.global

	if (Object.prototype.hasOwnProperty.call(locales, locale)) {
		i18n.locale = locale
		next()
	} else {
		i18n.locale = locales.en
		next({
			path: `/${locales.en}${to.path}`
		})
	}
})

export default router
