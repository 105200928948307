import $i18n from "@/language";
import moment from "moment";
import store from "@/store/index.js";
import router from "@/router/index.js";
import * as message from "@/plugins/message";
import constants from "@/libs/constants";
import health from "../http/apis/health";
import master from "@/http/apis/master";
import { BrowserQRCodeReader } from '@zxing/browser';

let agent = navigator.userAgent;
let i18n = $i18n.global;
let rcd = "rcd";

export const isMoblie = () => {
  return (
    agent.indexOf("iPhone") > -1 ||
    agent.indexOf("iPad") > -1 ||
    agent.indexOf("Android") > -1
  );
};

export const t_p = function (index, section, key) {
  return t(rcd, index, section, key);
};

export const tm_p = function (index, section, key) {
  return tm(rcd, index, section, key);
};

export const t = function (module, index, section, key) {
  return i18n.t(getPath(module, index, section, key));
};

export const tm = function (module, index, section, key) {
  return i18n.tm(getPath(module, index, section, key));
};

function getPath(module, index, section, key) {
  let str = `${module}.${index}.${section}`;
  if (key && key != "") {
    str = `${str}.${key}`;
  }
  return str;
}

export function parseJWT(jwt) {
  var base64Url = jwt.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function authenticateJWT(jwt) {
  if (!jwt) return false;
  let jwtObj = parseJWT(jwt);
  let expiryUnixTimestamp = moment(jwtObj.exp * 1000);
  return expiryUnixTimestamp.isAfter(moment());
}

export function authenticateJWTCuC(jwt) {
  if(!authenticateJWT(jwt)) return false;

  let jwtObj = parseJWT(jwt);
  const jwtKeys = Object.keys(jwtObj);
  if(!jwtKeys.includes("stn-no") || !jwtKeys.includes("stn-code")) return false;
  if(isNaN(jwtObj["stn-no"])) return false
  return true;
}

export async function getJWT() {
  let jwt = null;
  await master
    .getMaintenance("en")
    .then((res) => {
      jwt = res.headers["x-token"];
      store.commit("updateRCDJWT", jwt);
    }).catch((err) => {
      console.log("ErrorGetJWT");
      handleRCDException(err);
    });
  return jwt;
}

export async function refreshJWT() {
  let jwt = store.state.rcd.jwt;
  await health
    .getHealth(jwt)
    .catch((err) => {
      console.log("ErrorGetJWT");
      handleRCDException(err);
    });
  return jwt;
}

export function createFormSubmissionObj(form) {
  let octpusInfo = {
    cardNumber: Number(form.octopus.number),
    checksum: Number(form.octopus.checksum)
  };

  let name = `${form.name.surname?? ""} ${form.name.firstName?? ""}`;
  let nameChi = form.nameChi;
  let idType = form.identity.type;
  let idNum = genIDNum(idType, form.identity.number, form.identity.checksum);
  let mobile = form.mobile;
  let phone = form.phone
  let addr1 = form.address1;
  let addr2 = form.address2;
  let addr3 = form.address3;
  let personObj = {
    identityType: parseInt(idType),
    identityNumber: idNum ? idNum.toUpperCase() : null,
    name: name.trim() ? name.trim().toUpperCase() : null,
    nameChi: nameChi ? nameChi : null,
    gender: parseInt(form.gender),
    birthday: moment(new Date(form.birthday)).format("YYYY-MM-DD"),
    mobile: mobile ? mobile : null,
    eMail: form.email,
    officePhone: phone ? phone : null
  };
  let addrObj = {
    address1: addr1 ? addr1.toUpperCase() : null,
    address2: addr2 ? addr2.toUpperCase() : null,
    address3: addr3 ? addr3.toUpperCase() : null,
    district: form.district,
    region: form.area,
  };

  let parentObj = null;
  if (form.parentInfo.relationship && isBelow18(new Date(form.birthday))) {
    parentObj = {
      name: null,
      nameChi: null,
      relationship: parseInt(form.parentInfo.relationship),
      identityNumber: null,
      identityType: null
    };
    let parentLang = form.parentInfo.name.lang;
    let path = "name";
    if (parentLang == "Chi") path += parentLang;
    let value = form.parentInfo.name.value;
    parentObj[path] = value ? value.toUpperCase() : null;

    parentObj.identityType = parseInt(form.parentInfo.identity.type);
    idNum = genIDNum(parentObj.identityType, form.parentInfo.identity.number, form.parentInfo.identity.checksum);
    parentObj.identityNumber = idNum ? idNum.toUpperCase() : null;
  }

  return {
    personalInformation: personObj,
    address: addrObj,
    octopusInformation: octpusInfo,
    parent: parentObj,
    permitNo: form.closedAreaPermit ?? form.parentInfo.closedAreaPermit,
    proofType: Object.values(constants.proofType).indexOf(form.proofType)
  };
}

export function createUploadObj(formId, identityNumber, fileObj, folderType) {
  const base64Data = fileObj.file.data.split(',')[1];
  const binaryData = atob(base64Data);
  const uint8Array = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
  }

  const mimeType = fileObj.file.data.split(';')[0].split(':')[1];
  const blob = new Blob([uint8Array], { type: mimeType});

  const formData = new FormData();
  formData.append('formId', formId);
  formData.append('partialId', identityNumber);
  formData.append('file', blob, Object.keys(constants.folderType)[folderType] + `.${mimeType === "application/pdf" ? "pdf" : "jpeg"}`);
  formData.append('folderType', folderType);

  return formData;
}

export function genIDNum(type, number, checkSum) {
  if (!parseInt(type) && checkSum) {
    number += checkSum;
  }
  return number;
}

export function getNestedObj(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj);
}

export function regCheck(reg, value, spacing) {
  var pass = true;
  for (var x = 0; x < value.length; x++) {
    var char = value.charAt(x);
    if (spacing && !char.trim()) continue;
    pass = reg.test(char);
    if (!pass) break;
  }
  return pass;
}

export function mergeObject(base, input) {
  Object.entries(input).forEach(([key, value]) => {
    if (Object.prototype.hasOwnProperty.call(base, key) && typeof base[key] === "object") {
      base[key] = { ...base[key], ...value };
    } else {
      base[key] = value;
    }
  });
  return base;
}

export function goTo(path) {
  router.replace({
    name: path,
  });
}
export function openFareTable() {
  window.open(
    "/static/Fare_Table.pdf",
    "_blank"
  );
}

export function isInvalid(invalid, extraClass = "") {
  return `${invalid ? "invalid" : ""} ${extraClass}`;
}

export function triggerValidate(event, source, type) {
  if (type) {
    triggerInput(event.target, type);
  }
  source.$touch();
}

export function fullWidthStrConvertion(fullWidthStr) {
  let str = fullWidthStr.replace(/\s/g, String.fromCharCode(32));
  let res = "";
  for (var i = 0; i < str.length; i++) {
    if (str[i].charCodeAt(0) > 65280 && str[i].charCodeAt(0) < 65375) {
      res += String.fromCharCode(str[i].charCodeAt(0) - 65248);
    } else {
      res += String.fromCharCode(str[i].charCodeAt(0));
    }
  }
  return res;
}

export function triggerInput(elem, type) {
  let value = elem.value.trim();
  value = fullWidthStrConvertion(value);
  switch (type) {
    case "upper":
      value = value.toUpperCase();
      break;
    case "lower":
      value = value.toLowerCase();
      break;
  }
  elem.value = value;
  elem.dispatchEvent(new Event("input", { bubbles: true, cancelable: true }));
}

// @param dob Applicant's birthday, The value to be validated
// @param applyDate Optional parameter, used to verify the baseline time of the interval
export function isBelow18(dob, applyDate) {
  let check = moment(dob, "YYYY-MM-DD");
  const baseTime = applyDate === null ? moment() : moment(applyDate);

  return check.isAfter(baseTime.startOf('day').subtract(18, "years"))
}

export async function handleSessionExpired() {
    message.ConfirmDialogWithSingleBtn(i18n.t("rcd.dialog.sessionExpired.title"), i18n.t("rcd.dialog.sessionExpired.message"), 
      () => {
        router.replace({name: "StepStart"})
      },
      () => {}
    );
    return false;
}

export function handleRCDException(source, noAlert, specialTreatment401 = false) {
  let msg;
  if (!source.response) {
    msg = i18n.t("exceptions.default");
  } else {
    if (Object.prototype.hasOwnProperty.call(source.response.data, "ErrorCode")) {
      msg =
        source.response.data.Message[
          i18n.locale.charAt(0).toUpperCase() + i18n.locale.slice(1)
        ];
      let code = source.response.data.ErrorCode;
      switch (code) {
        case "CARD_SMART_OCTOPUS":
          noAlert = true;
          break;
      }
    } else {
      msg = i18n.t("exceptions.default");
      switch (source.response.status) {
        case 401 : {
          if(specialTreatment401){
            noAlert = true;
            return 401;
          }

          if (!authenticateJWT(store.state.rcd.jwt)) {
            noAlert = true;
            handleSessionExpired();
          }
          break;
        }
      }
    }
  }
  return handleExceptionMsg(noAlert, msg, source)
}

export function checkType(source, type) {
  let sourceType = null;
  
  switch(source) {
    case "identity":
      sourceType = store.state.rcd.form.identity.type
      break;
      case "isResident":
        sourceType = store.state.rcd.applicantType.question.isResident;
        break;
      case "above18":
        sourceType = store.state.rcd.applicantType.question.above18;
        break;
      case "moreThanThreeMonth":
        sourceType = store.state.rcd.applicantType.question.moreThanThreeMonth;
        break;
  }
  
  let result = sourceType == type;
  if (Array.isArray(type)) {
    result = type.some((val) => sourceType == val);
  }
  return result;
}

export function getOctopus() {
  return store.state.rcd.form.octopus;
}

export function checkStrLang(str) {
  return encodeURI(str).split(/%..|./).length - 1 == str.length ? constants.locale.en : constants.locale.ch
}

export function getOptionsLable(options, value) {
  for (let item of options) {
    if (item.value == value) {
      return item.label;
    }
  }
}

export function checkBackForwardAction() {
  let checkCount = 0;
  let interval = setInterval(()=> {
    console.log("checking", performance.getEntriesByType("navigation")[0].type)
    if(checkCount == 5) {
      clearInterval(interval);
      window.location.reload();
    }
    checkCount += 1;
  }, 2000)
}

export function formatIconClass(value) {
  if(Array.isArray(value)) {
    value = value.join(" ")
  }
  return value;
}

function handleExceptionMsg(noAlert, msg, source) {
  if (!noAlert) {
    message.WarningAlert(msg);
  }

  if (getNestedObj(source, "response", "data")) {
    return source.response.data;
  }
}

export function i18nPluralization(sourceMsg, limit, unit) {
  let limitKeys = Object.values(limit).filter(num => num > 0);
  let msg = sourceMsg.split("|")[limitKeys.length - 1];
  for (const [key, value] of Object.entries(limit)) {
    let numTarget = "num";
    let unitTarget = "unit"
    if (limitKeys.length > 1) {
      numTarget += `-${key}`;
      unitTarget += `-${key}`;
    }
    if (unit) {
      let unitArr = unit[key];
      let type = unitArr[0];
      if (value > 1 && unitArr.length > 1) {
        type = unitArr[1]
      }
      msg = msg.replace(`{${unitTarget}}`, `${type}`);
    }
    
    msg = msg.replace(`{${numTarget}}`, `${value}`);
    
  }
  return msg;
}


export async function getWebCameras(){
  let webCameras = [];

  const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  closeWebCamera(stream);
  
  const devices = await BrowserQRCodeReader.listVideoInputDevices();
  if(devices) {
    const template = i18n.locale == "en" ? "Camera" : "攝像頭";
    const front = "Front";
    const back = "Back"
    const frontText = i18n.locale == "en" ? front + " " : "前置";
    const backText = i18n.locale == "en" ? back + " " : "後置";

    for (let i = 0; i < devices.length; i++) {
      if(devices[i].label.toLowerCase().indexOf(front.toLowerCase()) == -1 
        && devices[i].label.toLowerCase().indexOf(back.toLowerCase()) == -1) {
        webCameras.push({ label: template + " "+(i+1), id: devices[i].deviceId});          
        continue;
      } 
      
      if(devices[i].label.toLowerCase().indexOf(front.toLowerCase()) > -1) {
        webCameras.push({ label: frontText + template, id: devices[i].deviceId});
        continue;          
      }

      if(devices[i].label.toLowerCase().indexOf(back.toLowerCase()) > -1) {
        webCameras.push({ label: backText + template, id: devices[i].deviceId});
        continue;          
      }
    }
  }

  return webCameras;
}

export async function openWebCamera(selectDevice) {
  const constraints = {
    video: { deviceId: selectDevice },
  };

  let videoStream = null;

  videoStream = await navigator.mediaDevices.getUserMedia(constraints);
  const track = videoStream.getVideoTracks()[0];
  let trackConstraints = {
    aspectRatio: { ideal: 4 / 3 }
  };
  if(window.innerHeight > window.innerWidth) {
    trackConstraints = {
      aspectRatio: { ideal: 3 / 4 }
    };
  }
  track.applyConstraints(trackConstraints);

  return videoStream;
}

export function closeWebCamera(stream) {
  if (stream) {
    stream.getTracks().forEach(track => track.stop());
  }
}