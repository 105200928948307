const getDefaultParentObj = () => {
  return {
    name: {
      lang: null,
      value: null,
    },
    relationship: null,
    closedAreaPermit: null,
    identity: getDefaultIdentityObj()
  };
};

const getDefaultIdentityObj = () => {
  return {
    type: 0,
    number: null,
    checksum: null,
  }
}

const getDefaultFileObj = () => {
  return {
    type: null,
    file: {
      name: null,
      data: null
    }
  }
}

const defaultForm = () => {
  return {
    id: null,
    proofType: null,
    name: getDefaultNameObj(),
    nameChi: null,
    gender: null,
    identity: getDefaultIdentityObj(),
    birthday: null,
    age: null,
    phone: null,
    mobile: null,
    email: null,
    address1: null,
    address2: null,
    address3: null,
    district: null,
    area: null,
    parentInfo: getDefaultParentObj(),
    photocopy: {
      closedAreaPermit: getDefaultFileObj(),
      proof: getDefaultFileObj()
    },
    closedAreaPermit: null,
    octopus: getDefaultIdentityObj(),
    qrCode: null
  };
};

const getDefaultNameObj = () => {
  return {
    firstName: null,
    surname: null
  }
}

const defaultApplicantType = () => {
  return {
    type: null,
    question: {
      isResident: null,
      above18: null,
      haveProof: null,
      moreThanThreeMonth: null,
      isStudent: null      
    }
  }
}

export default {
  state: {
    jwt: null,
    isMobile: null,
    type: "A",
    agreement: false,
    sendEmail: false,
    form: defaultForm(),
    applicantType: defaultApplicantType(),
    stayingAtStep: null
  },
  getters: {
    stepType: (state) => {
      return state.type;
    },
    rcdJWT: (state) => {
      return state.jwt;
    }
  },
  mutations: {
    updateLocale(state, val) {
      state.locale = val
    },
    updateForm(state, val) {
      state.form = val;
    },
    updateAgreement(state, val) {
      state.agreement = val;
    },
    updateRCDJWT(state, val) {
      state.jwt = val;
    },
    updateAppNo(state, val) {
      state.form.id = val.id;
      state.form.qrCode = val.qrCode;
    },
    updateSendEmail(state, val) {
      state.sendEmail = val;
    },
    updateStayingAtStep(state, val){
      state.stayingAtStep = val;
    },
    resetAddressInfo(state) {
      state.form.address1 = null;
      state.form.address2 = null;
      state.form.address3 = null;
      state.form.district = null;
      state.form.area = null;
    },
    resetParentInfo(state) {
      state.form.parentInfo = getDefaultParentObj();
    },
    resetIdentityInfo(state) {
      state.form.identity = getDefaultIdentityObj();
    },
    resetOctopusInfo(state) {
      state.form.octopus = getDefaultIdentityObj();
    },
    resetFormField(state, field){
      state.form[field] = null;
    },
    resetRCDForm(state) {
      let formObj = defaultForm();
      formObj.id = state.form.id;
      formObj.qrCode = state.form.qrCode;
      state.form = formObj;
    },
    resetNameObj(state) {
      state.form.name = getDefaultNameObj();
    },
    resetRCDState(state, removeJWT = true) {
      let formObj = defaultForm();
      if (removeJWT) {
        state.jwt = null;
      }
      state.agreement = false;
      state.sendEmail = false;
      state.applicantType = defaultApplicantType();
      state.form = formObj;
      state.stayingAtStep = null;
    },
    resetStayingAtStep(state){
      state.stayingAtStep = null;
    },
    resetAppNo(state) {
      state.form.id = null;
      state.form.qrCode = null;
    },
    updateIsMobile(state, val) {
      state.isMobile = val
    },
    updateApplicantTypeQuestion(state, val){
      state.applicantType.question = val;
    }
  },
};
